.container
  max-width: 120rem
  width: 100%
  padding: 0 15px
  margin: 0 auto
  z-index: 2

  +sm
    max-width: 90vw

  +xs
    width: 100%
    padding: 0 7.5px
