@import "./common";

.wrapper {
	position: fixed;
	bottom: 48px;
	right: 40px;
	z-index: 1001;
	width: $chat-body-width;
	max-height: 590px;
	background-color: rgba(255, 255, 255, 1);
	border-radius: $border-radius;

	&.mobile {
		top: 0;
		left: 0;
		right: 0;
		z-index: 2002;
		height: 100%;
		width: 100%;
		max-height: unset;
		border-radius: 0;
	}
}

.window {
	width: 100%;
	height: 100%;
	//box-shadow: 0 5px 17px 0 rgba(0, 0, 0, .15);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.header {
	margin-bottom: 0 !important;
	min-height: unset !important;
	padding: 0 !important;
	height: $chat-header-height;
	border-radius: $border-radius $border-radius 0 0;
}

.body {
	padding: 0 !important;
	height: $chat-body-height;
	border-radius: 0 0 $border-radius $border-radius;

	&.mobile {
		height: $chat-body-mobile-height;
		border-radius: 0;
	}
}

.floatButton {
	height: 48px;
	width: 48px;
	right: 48px;
	bottom: 12px;
	&.mobile {
		right: 12px;
	}
}

.floatButtonBody {
	:global(.ant-float-btn-body) {
		background-color: #5390E9;
		box-shadow: 0 0 6px 0 rgba(83,144,233,0.70);

		&:hover {
			background-color: #06519E;
			box-shadow: 0 0 6px 0 rgba(83,144,233,0.70);
		}
	}
	:global(.ant-float-btn-icon) {
		width: 24px !important;
	}
}

.hasUnread {
	:global(.ant-float-btn-body) {
		background-color: #E64343;
		box-shadow: 0 0 6px 0 #E64343;
	}
}
