.lang-select {
	position: relative;

	.flag {
		display: flex;

		svg,
		img {
			width:  3rem;
			height: 2rem;
		}
	}

	&.open &-arrow {
		transform: rotate(180deg);
	}

	&-header {
		display: flex;
		align-items: center;
		gap: 1rem;
		background: none;
		border: none;
		background: #F3F6FA;
		padding: .8rem 1rem;
		border-radius: .7rem;
	}

	&-options {
		position: absolute;
		left: 0;
		top:  100%;

		background: #F3F6FA;
		border-radius: .7rem;
		// width: 100%;
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: .5rem;

		a {
			width: 100%;
			background: none;
			border: none;
			padding: .4rem 1rem;
			display: flex;
			align-items: center;
		}
	}
}
