@import "src/styles/colors";

.list {
	height: 100%;
	padding: 18px;
	overflow: scroll;
}

.chatItemTitle {
	font-size: 14px;
}

.item {
	background-color: $background-secondary;
	border-radius: 12px;
	margin-bottom: 6px;
	padding: 12px;
	&:hover {
		cursor: pointer;
		background-color: $background-tertiary;
	}
}

.title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;

	opacity: 1;
	color: rgba(10, 38, 83, 1);
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
}

.subTitle {
	margin-bottom: 8px;
	font-size: 12px;
	color: $text-description-grey;
	font-weight: 500;
}

.footer {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	font-size: 11px;
	font-weight: 500;
	color: $grey;
}
