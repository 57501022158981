@import 'src/styles/gaps';
@import "src/styles/colors";
@import "../common";

$chat-list-height: calc($chat-body-height - $chat-footer-height - 1px);
$mobile-chat-list-height: calc(100dvh - $chat-header-height - $chat-footer-height - 1px);
$received-message-color-1: #ecf3fd;
$received-message-color-2: #e6f5ed;
$sent-message-color: #FDF7E3;

.spinnerWrapper {
	height: $chat-list-height;
	width: 100%;
	padding: $gap-16;
	&.mobile {
		height: $mobile-chat-list-height;
	}
}

.spinner {
	width: 100%;
	margin: 0 auto $gap-16;
}

.spinnerText {
	text-align: center;
}

.empty {
	padding: $gap-16;
	height: $chat-list-height;
	&.mobile {
		height: $mobile-chat-list-height;
	}
}

.list {
	padding: 18px 18px 12px;
	height: $chat-list-height;
	overflow-y: scroll;
	&.mobile {
		height: $mobile-chat-list-height;
	}
}

.item {
	border-radius: 12px;
	margin-bottom: 6px;
	padding: 12px;
	max-width: 80%;
	&:last-child {
		margin-bottom: 0;
	}
}

.left {
	border-bottom-left-radius: 0;
}

.right {
	margin-left: auto;
	border-bottom-right-radius: 0;
}

.text {
	color: $text-primary;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 14px;
}

.footer {
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	font-size: 11px;
	font-weight: 600;
	color: $grey;
}

.sentMessageColor {
	background-color: $sent-message-color;
}

.receiverColorOne {
	background-color: $received-message-color-1;
}
.receiverColorTwo {
	background-color: $received-message-color-2;
}
