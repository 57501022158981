.storage-section6 {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  padding-top: 9.1rem;
  padding-bottom: 6.4rem;

  @include sm {
    padding-top: 3.6rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 4.6rem;

    @include sm {
      gap: 4.8rem;
    }
  }

  &__steps-container {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    @include sm {
      flex-direction: column;
    }

    &_tile {
      display: flex;
      flex-direction: column;
      padding: 1.6rem 2.8rem;
      gap: 2.4rem;
      width: 36rem;

			&-contant {
				height: 23rem;
				min-height: 23rem;
			}

      @include sm {
        width: 98%;
        min-width: 98%;

        height: 26rem;
        min-height: 26rem;
      }

      background-color: #fff;
      box-shadow: 0 .9rem 11.1rem rgba(0, 0, 0, 0.05), 0px 1.01364px 28.7341px rgba(0, 0, 0, 0.0308023);
      border-radius: 1.5rem;

      &:last-of-type {
				@include sm {
					height: 36rem;
					gap: unset;
					min-height: unset;
				}

        a {
          height: 5.6rem;
          min-height: 5.6rem;

					width: 100%;
        }
      }

      svg {
        display: flex;
      }

      &_number {
        font-style: normal;
        font-weight: 700;
        font-size: 7.84rem;
        line-height: 110%;

        color: #0A2653;
      }

      h2 {
        margin: 0;
        margin-top: 2.2rem;
        margin-bottom: 1.2rem;

        font-weight: 700;
        font-size: 2rem;
        line-height: 120%;

        color: #0A2653;
      }

      &_description {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 140%;

        color: rgba(10, 38, 83, 0.64);
      }
    }
  }
}
