.search-results {
	padding: 4rem 0 20rem;

	@include sm {
		padding: 2.5rem 0 5.5rem;
	}

	.section-title {
		margin-bottom: 4.5rem;

		@include sm {
			margin-bottom: 2.5rem;
		}
	}

	&-text {
		text-align: justify;
	}

	&-inner {
		display: flex;
		gap: 4rem;
		align-items: flex-start;

		@include sm {
			flex-direction: column;
			gap: 2.5rem;
		}
	}

	h2 {
		position: relative;

		svg {
			position: absolute;
			right: 0;
			top: 25%;
		}
	}

	&-options {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 3rem;
		gap: 3rem;

		max-width: 33.5rem;
		width: 100%;
		background: #4587e8;
		box-shadow:
			0 0.9rem 11.1rem rgba(0, 0, 0, 0.05),
			0 0.1rem 2.8rem rgba(0, 0, 0, 0.0308023);
		border-radius: 1.5rem;

		color: white;

		&-title {
			margin: 0;
		}

		.select__container,
		.input__container {
			> span {
				color: #ebf2ff;
			}
		}
	}

	&-content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 100%;

		&__count {
			color: rgba(10, 38, 83, 0.64);
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 500;
			line-height: 140%;
			text-transform: uppercase;

			@include sm {
				margin: 0;
				font-size: 1rem;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}
	}

	.btn-more {
		width: fit-content;
		margin: 2rem auto 0;
	}
}
