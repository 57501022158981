.storage-section1 {
	position: relative;
	background: var(--accent-color);
	border-radius: 0 0 4rem 4rem;
	display: flex;
	align-items: center;

	color: white;
	text-align: center;

	@include sm {
		padding: 5rem 0;
		border-radius: 0 0 2rem 2rem;
		text-align: left;
	}

	&-title {
		max-width: 71rem;
		margin: 0 auto;
		margin-bottom: 2.3rem;

		font-size: 4rem;
		font-weight: 700;
		line-height: 110%;

		@include sm {
			margin-bottom: 4rem;

			font-size: 3.2rem;
		}
	}

	&-subtitle {
		max-width: 58rem;
		margin: 0 auto;
		margin-bottom: 3.5rem;

		font-size: 2rem;
		color: rgba(255, 255, 255, 0.7);
		font-weight: 400;
		line-height: 140%;

		@include sm {
			font-size: 1.6rem;
		}
	}

	a,
	button {
		margin: 0 auto;
		padding-top:    1.6rem;
		padding-bottom: 1.6rem;
		width: fit-content;
		font-weight: 700;

		@include sm {
			width: 100%;
		}
	}

	&-decor1,
	&-decor2 {
		pointer-events: none;

		@include sm {
			display: none;
		}
	}

	&-decor1 {
		position: absolute;
		height: 37rem;
		left: 9rem;
		top: 33rem;
	}

	&-decor2 {
		position: absolute;
		height: 38rem;
		left: 100rem;
		top:   31rem;
	}
}
