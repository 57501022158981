.storage-section2 {
	padding: 14rem 0 24rem;

	@include sm {
		padding: 5rem 0 6rem;
	}

	.section-title {
		@include sm {
			margin-bottom: 1.6rem;
			font-size: 2.7rem;
		}
	}

	.section-subtitle {
		font-size: 1.8rem;
		font-weight: 400;
		line-height: 140%;
		color: rgba(10, 38, 83, 0.64);
	}

	&-card {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 3rem;
		gap: 1.2rem;

		max-width: 33.333%;
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0 1.7rem 11.8rem rgba(0, 0, 0, 0.08);
		border-radius: 1.5rem;

		@include sm {
			max-width: unset;
			flex-direction: row;
			align-items: flex-start;
			padding: 2rem;
			gap: 1rem;
			box-shadow:
				0 .9rem 11.1rem rgba(0, 0, 0, 0.05),
				0 .1rem 2.8rem rgba(0, 0, 0, 0.0308023);
		}

		svg {
			margin-bottom: 1.2rem;
			width:  4.2rem;
			height: 4.2rem;

			@include sm {
				flex-shrink: 0;
				width:  3.2rem;
				height: 3.2rem;
			}
		}

		h3 {
			margin: 0;
			margin-bottom: 1.25rem;

			font-weight: 700;
			font-size: 2rem;
			line-height: 120%;

			@include sm {
				margin-top: .35rem;
				margin-bottom: .5rem;

				font-weight: 600;
				font-size: 18px;
				line-height: 140%;
			}
		}

		p {
			max-width: 25rem;
			margin: 0;

			font-size: 1.6rem;
			color: rgba(10, 38, 83, 0.64);
			font-weight: 400;
			line-height: 140%;

			@include sm {
				font-size: 1.2rem;
				font-weight: 400;
				line-height: 140%;
			}
		}
	}
}
