.nav-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.nav-left {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.circle {
	height: 0.7rem;
	width: 0.7rem;
	background-color: #e64343;
	border-radius: 50%;
	display: inline-block;
	margin: 0.3rem;

	&-menu {
		height: 2.2rem;
		width: 2.2rem;
		text-align: center;
		color: #FFFFFF;
		font-weight: bold;
		font-size: 1rem;
		background-color: #e64343;
		border-radius: 50%;
		display: inline-block;
		padding-top: 0.4rem;
	}
}

.bold {
	font-weight: bold;
}

.personal-account {
	background-color: #f0f6ff;
	display: flex;
	padding: 2rem;

	&-card {
		background: #ffffff;
		border-radius: 1rem;
		padding: 2rem;
	}

	&-subcard {
		display: inline-flex;
		flex-direction: column;
		background: #f3f7fa;
		border-radius: 1.2rem;
		padding: 2.2rem;

		&-title {
			margin: 0;
			margin-bottom: 1em;

			font-weight: 700;
			font-size: 1.4rem;
			line-height: 120%;
			color: #0a2653;
		}

		p {
			margin: 0;
		}
	}

	&__inner {
		position: relative;

		display: flex;
		gap: 2rem;
		width: 100%;

		@include sm {
			flex-direction: column;
		}
	}

	&__navigation-bar {
		overflow-y: auto;

		position: fixed;

		display: flex;
		flex-direction: column;
		width: 30.3rem;
		height: 87vh;
		min-height: 87vh;
		background: #ffffff;
		border-radius: 1rem;
		padding: 3.5rem;
		padding-right: 2.4rem;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			width: 0;
		}

		@include sm {
			position: static;
			width: 100%;
			height: unset;
			min-height: unset;
			padding: 1.5rem 2.25rem;
		}

		h2 {
			margin: 0;
			width: 100%;
			min-width: 100%;

			font-size: 2.5rem;
			font-weight: 700;
			line-height: 110%;
			color: #0a2653;
		}

		nav {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 0.3rem;

				@include sm {
					gap: 0.1rem;
				}

				li {
					width: 100%;

					&.active {
						background-color: #14489d;
						// background-color: #F0F6FF;
						border-radius: 0.8rem;
						color: #f0f6ff;

						svg {
							fill: #f0f6ff !important;

							path {
								fill: #f0f6ff !important;
								// fill: #14489D !important;
							}
						}

						span {
							// color: #14489D;
							color: #f0f6ff;
						}
					}
				}

				a {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 1.6rem;
					min-width: 100%;
					width: 100%;
					padding: 1.2rem 1.4rem;
					border-radius: 0.8rem;

					transition: 0.3s;

					cursor: pointer;

					@include sm {
						padding: 0.8rem 1.4rem;
					}

					&:hover {
						background-color: rgba(55, 110, 164, 0.06);
					}

					svg {
						flex-shrink: 0;
						width: 2rem;
						height: 2rem;
					}

					span {
						font-size: 1.6rem;
						color: #0a2653;
						font-weight: 400;
						line-height: 140%;

						user-select: none;
					}
				}
			}
		}

		&_storage-name {
			margin-left: 0.225rem;
			margin-top: auto;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 1.7rem;

			color: #0a2653;
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 700;
			line-height: 120%;

			@include sm {
				margin-top: 1.5rem;
			}
		}
	}

	&__pages-container {
		flex-grow: 1;
		max-width: 106.5rem;
		margin-left: 30.3rem + 2rem;

		@include sm {
			margin-left: 0;
		}
	}

	&-main {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		&-title {
			margin: 0;

			font-size: 2.5rem;
			color: #0a2653;
			font-style: normal;
			font-weight: 700;
			line-height: 110%;
		}

		&-subtitle {
			margin-top: 0.8rem;

			font-weight: 400;
			font-size: 1.6rem;
			line-height: 140%;
			font-style: normal;
			color: rgba(10, 38, 83, 0.64);
		}

		&-tag {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 0.2rem 0.6rem;
			gap: 1rem;
			background: rgba(69, 135, 232, 0.1);
			border-radius: 0.5rem;

			font-style: normal;
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 140%;
			color: #4587e8;
		}

		&-list {
			list-style: none;
			margin: 0;
			margin-top: 3.5rem;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			time {
				display: flex;
				align-items: center;
				gap: 1rem;

				font-weight: 700;
				font-size: 1.4rem;
				color: rgba(10, 38, 83, 0.64);
			}
		}
	}

	&-navigation {
		background: #f3f7fa;
		border-radius: 1rem;
		list-style: none;
		padding: 0.4rem 1rem;
		margin: 0;
		display: flex;
		gap: 0.5rem;

		@include sm {
			flex-direction: column;
			align-items: center;
		}

		button {
			background: none;
			padding: 1rem;
			border: none;

			font-size: 1.4rem;
			font-weight: 700;
			white-space: nowrap;

			&.active {
				background: #ffffff;
				box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.04);
				border-radius: 1rem;
			}
		}
	}

	&-overflow-auto {
		overflow-x: auto;
	}

	&-table {
		width: 100%;
		padding: 1.4rem 1.6rem;
		background: rgba(55, 110, 164, 0.06);
		border-radius: 1rem;

		@include sm {
			display: none;
		}

		a {
			color: var(--accent-color);
		}

		&-head &-col {
			padding: 1.75rem 1.25rem;

			font-size: 1rem;
			line-height: 1.4rem;
			text-transform: uppercase;
			white-space: nowrap;
			text-align: center;
			color: rgba(10, 38, 83, 0.64);
		}

		&-body {
			background: #ffffff;
			border-radius: 0.6rem;
		}

		&-body &-col {
			width: 2rem;
			padding: 2rem 1.25rem;
		}

		&-col {
			text-align: center;
		}

		&-card {
			background: white;
			padding: 1rem 1.25rem;
			border-radius: 1rem;

			h3 {
				margin: 0;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				border-bottom: 0.1rem solid darken(#eff2f5, 10);

				font-style: normal;
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 140%;
				color: #0a2653;
			}

			dl {
				display: flex;
				flex-direction: column;
				gap: 0.8rem;

				font-size: 1.4rem;
				font-style: normal;
				font-weight: 500;
				line-height: 140%;

				div {
					display: flex;
					align-items: center;
				}
			}

			dt {
				min-width: 14.5rem;

				color: rgba(10, 38, 83, 0.64);
				text-transform: uppercase;
			}

			dd {
				margin: 0;
			}
		}
	}

	&-bailor {
		&-info {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.location {
				font-size: 1.6rem;
				line-height: 140%;
				color: rgba(10, 38, 83, 0.64);
			}

			dl {
				margin: 0;
				display: flex;
				flex-direction: column;
				gap: 1rem;

				font-size: 1.4rem;
				line-height: 140%;
				color: rgba(10, 38, 83, 0.64);

				> div {
					display: flex;
					align-items: center;
					gap: 1rem;
				}

				dd {
					margin: 0;
					color: #0a2653;
				}
			}
		}

		&-progress {
			&-percent {
				b {
					font-size: 1.8rem;
					color: #14489d;
				}
			}

			&-volume {
				font-size: 1.4rem;
				color: rgba(10, 38, 83, 0.64);
			}
		}

		&-stats {
			margin-top: 4.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			font-size: 1.2rem;
			color: rgba(10, 38, 83, 0.64);
			font-weight: 400;
			line-height: 140%;
			text-align: center;
			gap: 1.2rem;

			&-label {
				margin-bottom: 0.8rem;

				font-size: 1.6rem;
				font-weight: 700;

				b {
					color: #14489d;
				}
			}

			&-separator {
				flex-shrink: 0;
				align-self: center;

				display: block;
				width: 0.1rem;
				height: 5.5rem;

				border: 0.1rem solid #eff2f5;
			}
		}

		&-actions {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			font-size: 1.6rem;
			color: rgba(10, 38, 83, 0.64);
			font-weight: 400;
			line-height: 140%;

			> * {
				position: relative;

				&::after {
					content: '';

					position: absolute;
					left: 0;
					bottom: -0.75rem;

					width: 25rem;
					height: 0.1rem;
					border: 0.1rem solid #eff2f5;
				}

				&:last-of-type::after {
					display: none;
				}
			}
		}
	}
}
