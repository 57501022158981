.section4 {
	background: white;
	padding: 7.5rem 0 3.5rem;
	border-radius: 0 0 4rem 4rem;
	display: flex;
	align-items: center;

	text-align: center;

	@include sm {
		padding: 6.6rem 0;
	}

	&-title {
		margin-bottom: 4rem;

		@include sm {
			margin-bottom: 3rem;
		}
	}

	&-applications {
		list-style: none;
		padding: 0;
		margin: 0;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2.5rem;
	}

	&-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 3rem;
		gap: 1.4rem;

		width: 36rem;
		background: white;
		box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
		border-radius: 15px;

		&-icon {
			margin-bottom: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;

			height: 6rem;

			img,
			svg {
				max-width:  100%;
				max-height: 100%;
			}
		}

		h3 {
			margin: 0;

			font-size: 1.8rem;
			color: #0A2653;
			font-weight: 700;
			line-height: 120%;
			text-align: center;
		}

		p {
			margin: 0;

			font-size: 1.6rem;
			color: rgba(10, 38, 83, 0.64);
			font-weight: 400;
			line-height: 140%;
			text-align: center;
		}

		button {
			margin-top: .75rem;

			font-weight: 700;
		}
	}
}
