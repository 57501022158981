@import "src/styles/colors";
@import "./../common";

.header {
	display: flex;
	height: $chat-header-height;
	align-items: center;
	background-color: $header-color;
	min-height: $chat-header-height;
	border-bottom: 1px solid #f0f0f0;
	border-radius: $border-radius $border-radius 0 0;
	font-size: 18px;


	&.mobile {
		border-radius: 0;
	}

	&:hover {
		cursor: pointer;
	}
}

.title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-left: 24px;
	width: 100%;
	overflow: hidden;
	&.chatOpen {
		padding-left: 0;
		width: calc(418px - 72px - 59px);
		overflow: hidden;
	}
}

.titleText {
	font-size: 18px;
	color: #fff !important;

	&.chatOpen {
		font-size: 12px;
	}
}

.subtitleText {
	font-size: 14px;
	font-weight: normal;
	color: #fff !important;
}

.iconToggle {
	display: flex;
	align-items: center;
	padding: 0 24px;
	text-align: center;
	box-sizing: content-box;
	color: #fff;
}

.backButtonWrapper {
	padding: 0 6px;
}

.backButton {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-content: center !important;
	color: #fff;

	&:hover {
		background-color: $blue5;
	}
}
