[class*='disabled'],
[disabled],
:disabled,
.ant-input-number-group-addon {
	cursor: default !important;

	&,
	* {
		cursor: default !important;
	}
}

body {
	min-height: unset;

	font-family: 'Lato', 'SF Pro', sans-serif;
	font-size: 1.6rem;
	color: var(--primary-color);
	line-height: 1.25;
}

main {
	@include sm {
		overflow: hidden;
	}

	> section {
		min-height: calc(100vh - 6.2rem);

		@include sm {
			min-height: unset;
		}
	}
}

// *:focus-visible {
// 	outline: .25em auto var(--accent-color) !important;
// 	outline-offset: .35rem;
// }

.sm-only {
	display: none !important;

	@include sm {
		display: flex !important;
	}
}

.hide-sm {
	@include sm {
		display: none !important;
	}
}

.section {
	&-title {
		margin: 0;

		font-size: 4rem;
		font-weight: 700;
		line-height: 110%;

		@include sm {
			font-size: 2.7rem;
		}
	}

	&-subtitle {
		margin: 0;

		font-size: 2rem;
		font-weight: 400;
		line-height: 140%;

		@include sm {
			font-size: 1.6rem;
		}
	}
}
