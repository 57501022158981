.search-panel {
	position: relative;
	padding-top: 7.8rem;
	padding-bottom: 4.5rem;

	background-color: #14489d;
	border-radius: 0px 0px 4rem 4rem;
	min-height: fit-content;

	&_svg {
		position: absolute;
		top: 6.6rem;
		left: 4.2rem;
	}

	h2 {
		color: #fff;
	}

	@include sm {
		padding-bottom: 4rem;
		padding-top: 2.7rem;
	}

	&__filters-block &__join {
		display: flex;
		gap: 2px;
		width: 200%;

		.input__container:first-child input {
			border-radius: 1rem 0 0 1rem;
		}

		.input__container:last-child input {
			border-radius: 0 1rem 1rem 0;
		}

		@include sm {
			flex-direction: column;
			gap: 1.6rem;
			width: 100%;

			.input__container:first-child input,
			.input__container:last-child input {
				border-radius: 1rem;
			}
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;

		gap: 2.6rem;

		@include sm {
			gap: 1.9rem;
		}
	}

	&__filters-block {
		position: relative;
		display: flex;
		justify-content: space-between;
		gap: 1.6rem;
		width: 100%;
		padding: 2.5rem 2.7rem 2.5rem;

		background: #ffffff;
		border-radius: 1.5rem;

		@include sm {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
	}
}
