@import "mixins"
@import "grid"
@import "vars"
@import "fonts"

/* normalize.css */
@import "../lib/normalize/normalize"

/* modern reset.css */
@import "../lib/reset.css/reset"

button,
input,
a
	&:focus-visible
		outline: none
		// outline: .1em auto var(--accent-color)
		// outline-offset: .35em

	&:focus:not(:focus-visible)
		outline: none

a
	display: inline-flex
	color: inherit
	text-decoration: none

button
	font-family: inherit

	cursor: pointer

br
	user-select: none

.visually-hidden
	+visually-hidden

html
	font-size: .694445vw // 10px on 1440px

	+sm
		font-size: 2.66525vw // 10px on 375px

body
	overflow-x: hidden
	min-width: 320px

	&.scroll-disabled
		height: 100vh
		overflow-y: hidden
