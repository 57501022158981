.storage-section4 {
  position: relative;
  padding-top: 20.8rem;
  //padding-bottom: 34.5rem;
  padding-bottom: 10rem;

  border-radius: 0 0 4rem 4rem;

  height: fit-content;

  @include sm {
    padding-top: 38.9rem;
    padding-bottom: 5rem;
  }

  &__gradient-map {
    position: absolute;
    top: 37.5%;
    transform: translateY(-50%);
    left: 4.1rem;

    z-index: -1;

    width: 118.4rem;
    height: 53.3rem;
    user-select: none;

    @include sm {
      min-width: 375px;
      min-height: 432px;

      left: -2.4rem;
      top: -20rem;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    h2 {
      width: 39.8rem;

      @include sm {
        width: 30rem;
      }
    }

    span {
      width: 33.8rem;
      color: #0A2653A3;

      @include sm {
        width: 20rem;
      }
    }
  }

  &__total-list {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin-top: 8.8rem;

    @include sm {
      margin-top: 3rem;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        display: flex;
        align-items: flex-end;
        gap: .8rem;

        font-weight: 400;
        font-size: 2rem;
        line-height: 140%;
        color: #14489D;

        span {
          width: 5.6rem;
          min-width: 5.6rem;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 110%;

          text-align: right;
        }
      }
    }

  }

  &__button-container {
    display: flex;
    margin-top: 5.6rem;
    width: 100%;

    @include sm {
      margin-bottom: 3.9rem;
    }

    button {
      width: 26.4rem;

      @include sm {
        width: 100%;
      }
    }

    @include sm {
      margin-top: 3.9rem;
    }
  }

  &__parthners-list {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    width: 100%;
    margin-top: 8.8rem;

    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 110%;

      color: #0A2653;
    }

    @include sm {
      gap: 2rem;
      margin-top: 3.6rem;
    }

    ul {
      display: flex;
      gap: 2.2rem;
      align-items: center;
      justify-content: flex-start;
      margin:  0;
      padding: 0;

      @include sm {
        flex-direction: column;
        gap: 1.3rem;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 3.2rem 1rem 1.3rem;
        width: 21rem;

        border: 1px solid rgba(42, 84, 126, 0.09);
        border-radius: 1.5rem;
        list-style: none;

        @include sm {
          height: 7rem;
          width: 100%;
          align-items: center;
          justify-content: flex-start;

          padding: 1rem 3.2rem 1rem 1.3rem;
        }

        span {
          display: flex;
          align-items: center;
          text-align: center;
          gap: 1.6rem;

          font-weight: 700;
          font-size: 1,6rem;
          line-height: 120%;


          color: #0A2653;


          img {
            width:  5.4rem;
            height: 5.4rem;
          }
        }
      }
    }
  }
}
