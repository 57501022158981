@import "src/styles/colors";
@import "../common";

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: $chat-footer-height;
	padding: 0 $general-padding;
	border-top: 1px solid #ddd;
}

.submitButton {
	margin: 0 0 0 12px;
	width: $chat-text-input-height;
	height: $chat-text-input-height;
}

.input {
	height: $chat-text-input-height;
	margin: 0 !important;
}
