@import "@/styles/mixins";

.container {
	position: fixed;
	z-index: 10000;
	left: 50%;
	bottom: 5.8rem;

	padding-left: 2.6rem;
	padding-right: 2.6rem;

	transform: translateX(-50%);

	@include sm {
		left: 0;
		right: 0;
		bottom: 2.6rem;

		transform: none;
	}
}

.card {
	padding: 3rem;
	max-width: 46.8rem;
	width: 100%;

	border-radius: 1.5rem;
	background: #FFF;
	box-shadow: 0 .4rem .4rem 0 rgba(0, 0, 0, 0.25);
}

.text {
	margin: 0 0 2rem;

	color: rgba(10, 38, 83, 0.64);
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 2.2rem;
	text-align: left;
}

.footer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button {
	max-width: 13.6rem;
}
