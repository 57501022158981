@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/typography';
@import 'src/styles/gaps';

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: $gap-s;
	width: 100%;
	height: 4.5rem;
	padding: $gap-m;
	background-color: #fdf7e3;
	position: sticky;
	top: 0;
	z-index: 2002;

	@include sm {
		position: relative;
		z-index: 9001;
		padding: $gap-m;
	}
}

.icon {
	color: #faad14;
	width: 15px;
	height: 15px;
}
