.storage-section3 {
	position: relative;
	padding: 14rem 0 24rem;
	background: var(--accent-color);

	@include sm {
		padding: 5rem 0 6rem;
	}

	.section-title {
		color: white;

		@include sm {
			margin-bottom: 1.6rem;
			font-size: 2.7rem;
		}
	}

	.section-subtitle {
		font-size: 1.8rem;
		font-weight: 400;
		line-height: 140%;
		color: rgba(255, 255, 255, 0.7);
	}

	.storage-section2-card {
		p {
			max-width: 28rem;

			@include sm {
				max-width: unset;
			}
		}
	}
}
