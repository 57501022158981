.section1 {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 11.3rem;
  padding-bottom: 11rem;

  background-color: var(--accent-color);
  border-radius: 0 0 2rem 2rem;

  @include sm {
    padding-top: 10.1rem;
    padding-bottom: 4.1rem;
  }

  svg {
    @include no-select();
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include sm {
      flex-direction: column;
      gap: 4.8rem;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    width: 54%;
    padding-top: 8.8rem;

    @include sm {
      width: 100%;
      gap: 1.5rem;
      padding-top: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 4rem;
      line-height: 110%;
      width: 60rem;

      color: white;

      @include sm {
        width: 100%;
        font-size: 3.1rem;
      }
    }

    span {
      color: rgba(255, 255, 255, 0.7);
      width: 50rem;

      @include sm {
        font-size: 1.6rem;
        width: 30rem;
      }
    }
  }

  &__form-container {
    position: relative;
    width: 50%;

    @include sm {
      width: 100%;
    }

    &_svg {
      position: absolute;
      bottom: 100%;
      right: 50%;
      transform: translateX(50%);
      margin-bottom: 1.8rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 3.6rem;
    background-color: #fff;
    gap: 2.4rem;

    box-shadow: 0px 9px 111px rgba(0, 0, 0, 0.05), 0px 1.01364px 28.7341px rgba(0, 0, 0, 0.0308023);
    border-radius: 1.5rem;

    @include sm {
      padding: 2.2rem;
      gap: 1.4rem;
    }

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 110%;

      color: #0A2653;

      @include sm {
        margin-bottom: 1rem;
      }
    }

    &_row {
      display: flex;
      gap: 1.3rem;

      input {
        @include sm {
          width: 100%;
        }
      }

      @include sm {
        flex-direction: column;
        gap: 1.4rem;
      }
    }
  }
}
