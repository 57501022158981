.button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: var(--accent-color);
	border: none;
	border-radius: .7rem;
	padding: .9rem 2.5rem;
	transition: .3s;

	font-family: 'Lato', 'sans-serif';
	color: #E7ECF3;
	font-weight: 500;
	font-size: 1.6rem;
	letter-spacing: 0.01em;

	transition: all .1s ease;

	&:hover {
		opacity: .8;
		cursor: pointer;
	}

	&:disabled {
		user-select: none;
		opacity: .7;
	}

	&.text {
		background: none;
		padding: 0;

		color: inherit;
	}

	&.form {
		width: 32rem;
		height: 5.6rem;
	}

	&.pale {
		background: rgba(55, 110, 164, 0.06);
		color: var(--accent-color);

		&:hover {
			background: rgba(55, 109, 164, 0.15);
		}
	}

	&.pale-with-border {
		background: white;
		color: var(--accent-color);
		border: 1px solid var(--accent-color);
	}

	&.danger {
		background: #E64343;
	}

	&.inverted {
		background: white;
		color: var(--accent-color);
	}

	&.link {
		padding: 0;

		text-decoration-line: underline;
		color: #4587E8;

		background-color: transparent;

		&:hover {
			color: var(--accent-color);
		}

		&:active {
			opacity: .5;
		}
	}
	&.loading {
		color: transparent;
		pointer-events: none;
		position: relative;
	}
}
.loader {
	height: 20px;
	width: 20px;
	font-size: 0;
	margin: auto;
	position: absolute;
	inset: 0;
}