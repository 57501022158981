.storage-section5 {
  position: relative;
  background-color: var(--accent-color);
  padding-top: 2rem;
  padding-bottom: 10rem;

  @include sm {
    padding-top: 10rem;
    padding-bottom: 3.3rem;
  }

  &__mobile-title {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 2.7rem;
      line-height: 110%;

      color: #FFFFFF;
    }

    span {
      font-weight: 400;
      font-size: 1.9rem;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.7);
    }
  }

  &_absolute {
    position: absolute;
    top: -20rem;
    left: 50%;
    transform: translateX(-50%);

    @include sm {
      position: unset;
      transform: none;
    }
  }

  &__title-block {
    display: flex;
    gap: 25.7rem;
    padding: 4.3rem 4.7rem;

    background: #FFFFFF;
    box-shadow: 0 .9rem 11.1rem rgba(0, 0, 0, 0.05), 0 1rem 2.87rem rgba(0, 0, 0, 0.0308023);
    border-radius: 1.5rem;

    @include sm {
      width: 100%;
      flex-direction: column;
      padding: 3.1rem 1.6rem 5rem;
      gap: 2rem;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 4rem;
      line-height: 110%;
      color: #0A2653;

      margin-top: 1.5rem;
      margin-bottom: 1.2rem;

      @include sm {
        margin-top: 2rem;
        margin-bottom: .8rem;

        font-size: 2rem;
      }
    }

    span {
      font-weight: 400;
      font-size: 2rem;
      line-height: 140%;
      color: rgba(10, 38, 83, 0.64);
    }

    &_small {
      font-weight: 400;
      font-size: 2rem;
      line-height: 140%;
      color: #14489D !important;
    }

    img {
      width: 29.9rem;
      height: 17.2rem;
    }

    &-content {
      @include sm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        order: 1;

        text-align: center;
      }
    }
  }

  &__tariffs {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 13rem;

    @include sm {
      flex-direction: column;
      margin-top: 3.5rem;

      gap: 1.6rem;
      background: var(--accent-color);
    }


    background-color: #FFFFFF;
    border-radius: 2rem;

    &_column {
      width: 26%;
      height: fit-content;
      padding-bottom: 3rem;

      @include sm {
        background-color: #fff;
        width: 100%;
        border-radius: 1rem;
      }

      &_row {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8.5rem;
        min-height: 8.5rem;

        @include sm {
          height: unset;
          min-height: unset;
          text-align: center;
        }

        border-top: 1px solid rgba(42, 84, 126, 0.09);
        border-right: 1px solid rgba(42, 84, 126, 0.09);

        & + * {
          @include sm {
            span {
              padding: 2rem;
            }

            &::after {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              width: 80%;
              height: 2px;
              background-color: rgba(42, 84, 126, 0.09);
            }
          }
        }


        @include sm {
          border: none;
        }

        &:first-of-type {
          height: 27.9rem;
          min-height: 27.9rem;
          padding: 4.9rem 2.1rem 3rem;

          @include sm {
            min-height: unset;
            height: 24rem;
            padding-bottom: 1rem;
          }

          .storage-section5__tariffs_column_row_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-self: flex-start;
            margin-top: 0.8rem;
            height: 100%;

            @include sm {
              margin-top: 0;
              width: 100%;

              a {
                width: 100%;
              }
            }

            &_text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .tariffName {
                font-weight: 700;
                font-size: 2.1rem;
                line-height: 110%;

                color: #14489D;
              }

              .tariffPrice {
                margin-top: 1.6rem;
                margin-bottom: .7rem;

                font-weight: 700;
                font-size: 2.5REM;
                line-height: 110%;

                color: #0A2653;
              }

              .tariffTime {
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 140%;

                color: rgba(10, 38, 83, 0.64);
              }
            }

          }
        }

        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;

          font-weight: 400;
          font-size: 1.6rem;
          line-height: 140%;


          img {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }

      &--first {
        display: flex;
        flex-direction: column;
        width: 30%;

        .storage-section5__tariffs_column_row {
          &:first-of-type {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            padding: 4.9rem 3.3rem;

            @include sm {
              padding-top: 3.8rem;
            }

            border-top: 1px solid rgba(42, 84, 126, 0.09);
            border-right: 1px solid rgba(42, 84, 126, 0.09);

            gap: 1.8rem;

            h2 {
              margin: 0;
              font-weight: 700;
              font-size: 4rem;
              line-height: 110%;

              color: #0A2653;
            }

            span {
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 140%;

              color: rgba(10, 38, 83, 0.64);
            }
          }

          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 2rem 2rem 2.2rem;
        }
      }
    }
  }
}
