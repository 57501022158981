.login {
	display: flex;
	min-height: calc(100vh - 6.2rem);

	&-subtitle {
		margin-top:    2rem;
		margin-bottom: 2rem;

		font-weight: 400;
		font-size: 1.6rem;
		line-height: 140%;

		color: rgba(10, 38, 83, 0.64);
	}

	&-inner {
		display: flex;
		background-color: white;
		width: 100%;
		min-width: 100%;
	}

	&-visual {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 48%;
		min-width: 48%;
		background: #F0F6FF;

		@include sm {
			display: none;
		}

		&-inner {
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			max-width: 48rem;

			img {
				margin-left: -3rem;
			}
		}

		&-text {
			img {
				width: 13.5rem;
				height: 2.5rem;
				margin-left: -.7rem;
				margin-bottom: 1.5rem;
			}

			span {
				font-style: normal;
				font-weight: 400;
				font-size: 1.8rem;
				line-height: 140%;

				color: rgba(10, 38, 83, 0.64);
			}
		}

		svg {
			display: flex;
			align-self: flex-end;
		}
	}

	&-form {
		display: flex;
		width: 50%;
		min-width: 50%;
		margin: auto;
		padding-left: 9.6rem;

		@include sm {
			padding: 2.75rem;
			width: 100%;
			min-width: unset;
		}

		&-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 45.6rem;
			min-width: 45.6rem;

			@include sm {
				min-width: unset;
				width: 100%;
			}

			h2 {
				margin: 0;
				font-style: normal;
				font-weight: 700;
				font-size: 4rem;
				line-height: 110%;

				color: #0A2653;
			}
		}
	}

	&__links_container {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-top: 3rem;
		text-align: center;

		button {
			color: #0909d5;
			opacity: .8;

			&:hover {
				opacity: .4;
			}
		}
	}
}
