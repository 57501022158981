@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/typography';
@import 'src/styles/functions';
@import 'src/styles/gaps';

.container {
	display: flex;
	flex-direction: column;
	gap: $gap-m;
}

.title {
	@include heading-xs;
}

.image {
	height: auto;
}

.buttonBlock {
	display: flex;
	gap: $gap-s;
	align-self: flex-end;
}

.okButton {
	background-color: $background-footer;
}
