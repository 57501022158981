@import 'src/styles/mixins';

.header {
	position: sticky;
	z-index: 2001;
	top: 0;
	left: 0;
	width: 100vw;
	background: white;
	box-shadow: 0 0.4rem 1.4rem rgba(0, 0, 0, 0.05);

	padding: 1.2rem 0;

	font-weight: 700;
	font-size: 1.4rem;
	color: var(--primary-color);

	&.visible {
		.header-inner {
			display: flex;
			padding-bottom: 10rem;
		}
	}

	@include sm {
		box-shadow: none;
		display: flex;
		justify-content: space-between;
		box-shadow: 0 0.4rem 1.4rem rgba(0, 0, 0, 0.05);

		padding-left: 2rem;
		padding-right: 2rem;
	}

	.logo {
		cursor: pointer;

		@include sm {
			display: none;
		}
	}

	.mobile-logo {
		position: absolute;
		z-index: 9999;
		left: 45%;
		top: 25%;
		transform: translateX(-50%);
		display: none;

		@include sm {
			display: block;
		}
	}

	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include sm {
			position: fixed;
			z-index: 9000;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			padding: 2.2rem 0 7.5rem;
			background: white;
			flex-direction: column;

			display: none;
		}

		.main-part-header {
			display: flex;
			flex-direction: row;
			gap: 10rem;
			align-items: center;
		}
	}

	.demo-mobile-header {
		@include sm {
			padding: 4.2rem 0 7.5rem;
		}
	}

	.burger-button {
		position: relative;
		z-index: 9999;

		background: none;
		border: none;
		padding: 0;
		display: none;
		justify-content: center;
		align-items: center;

		@include sm {
			display: flex;
		}

		svg:last-child {
			display: none;
		}

		&.active {
			svg:first-child {
				display: none;
			}
			svg:last-child {
				display: block;
			}
		}
	}

	.navigation {
		@include sm {
			margin-top: 4rem;
			margin-bottom: auto;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			gap: 3.5rem;

			@include sm {
				flex-direction: column;
			}
		}
	}

	.mobile-navigation {
		@include sm {
			margin-top: 8rem;
			margin-bottom: auto;
		}
	}

	&-phone {
		display: flex;
		align-items: center;
		gap: 0.25em;
		padding: 0.7rem 0.4rem;

		color: #14489d;
		font-weight: 700;
	}

	&-user {
		display: flex;
		align-items: center;
		gap: 1rem;

		color: #0a2653;

		&-avatar {
			display: flex;
			width: 3.4rem;
			height: 3.4rem;
			border-radius: 50%;
			background: #f0f6ff;
		}

		&-name {
			font-size: 1.2rem;
			font-weight: 700;
			line-height: 120%;
		}

		&-role {
			font-size: 1rem;
			font-weight: 400;
			line-height: 140%;
			color: rgba(10, 38, 83, 0.64);
		}
	}
}
