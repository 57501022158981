$chat-body-height: 450px;
$chat-body-width: 380px;
$general-padding: 18px;
$chat-header-height: 70px;
$chat-body-mobile-height: calc(100vh - $chat-header-height);

$chat-text-input-height: 40px;
$chat-footer-height: 76px;

$header-color: #618ee2;
$border-radius: 12px;
