.antMobile {
	.ant-modal-content {
		padding: 0;
		background-color: #06519e !important;
	}

	.ant-modal-confirm-paragraph {
		max-width: 100%;
	}
}
